@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.footer{
    align-items: center;
    background-color: #2c2c33;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    margin-bottom: -8.5px;
    padding: 14px 0px;
    position: relative;
    width: 100%;
}

.desenvolvidoby{
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

a{
    text-decoration: none;
}

.href:hover,
.href:focus{
    color: #ffffff;
}

.href{
    color: #ffffff;
    cursor: pointer;
}

.href:hover{
    transition: underline 0.35s ease;
    text-decoration: underline;
}

@media only screen and (min-width: 320px) and (max-width: 499px) {

    .footer{
        padding: 8px 0px;
    }

    .desenvolvidoby{
        font-size: 10px;
    }
}