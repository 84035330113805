.App {
  text-align: center;
}

/* Estilizando a barra de rolagem */
::-webkit-scrollbar {
  width: 10px; /* largura da barra de rolagem */
}

/* Estilizando o botão da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #888; /* cor do botão */
  border-radius: 5px; /* borda do botão */
}

/* Estilizando a área da barra de rolagem */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* cor de fundo da área da barra de rolagem */
}

/* Estilizando o canto inferior direito da área da barra de rolagem */
::-webkit-scrollbar-corner {
  background-color: transparent; /* cor de fundo do canto inferior direito */
}
