@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.container {
    padding: 0px 15px 0px 15px;
}

.logo img{
    width: 80px;
    height: 50px;
}

@media (min-width: 0) {
    .container {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .container:after {
        content: " ";
        display: block;
        clear: both;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
        margin-left: auto;
        margin-right: auto;
    }

    .container:after {
        content: " ";
        display: block;
        clear: both;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
    }

    .container:after {
        content: " ";
        display: block;
        clear: both;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
    }

    .container:after {
        content: " ";
        display: block;
        clear: both;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto;
    }

    .container:after {
        content: " ";
        display: block;
        clear: both;
    }
}

a {
    text-decoration: none;
    color: rgba(34, 34, 34, 0.8);
}

a:hover,
a :focus {
    color: #2c2c33;
}

.menu_left a {
    display: inline-block;
    position: relative;
    padding-bottom: 0px;
    transition: color 0.35s ease;
    color: #2c2c33;
    font-weight: 500;
}

/*Linha abaixo do menu*/
.menu_left a:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    transition: width 0s ease, background 0.35s ease;
}

/*Linha abaixo do menu*/
.menu_left a:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    background: #000;
    transition: width 0.35s ease;
}

/*Linha abaixo do menu*/
.menu_left a:hover:before {
    width: 100%;
    background: #2c2c33;
    transition: width 0.35s ease;
}

/*Linha abaixo do menu*/
.menu_left a:hover:after {
    width: 100%;
    background: transparent;
    transition: all 0s ease;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem 0;
    background: #ded7c7f3;
    z-index: 100;
    will-change: transform;
    transition: background 0.3s, -webkit-transform 0.5s cubic-bezier(0.694, 0.048, 0.335, 1);
    transition: transform 0.5s cubic-bezier(0.694, 0.048, 0.335, 1), background 0.3s;
    transition: transform 0.5s cubic-bezier(0.694, 0.048, 0.335, 1), background 0.3s, -webkit-transform 0.5s cubic-bezier(0.694, 0.048, 0.335, 1);
    transform: translateY(0);
    -webkit-transform: translateY(0);
}

header nav .logo {
    height: 43px;
    left: 0;
    letter-spacing: 0;
    float: left;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: normal;
    font-weight: 500;
    font-family: "Philosopher", sans-serif;
    color: #2c2c33;
}

header nav .logo:after {
    content: "";
    display: table;
    clear: both;
}

header nav ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

header nav ul li {
    font-family: "Poppins", sans-serif;
    float: none;
    margin-left: 0;
}

@media (min-width: 768px) {
    header nav ul li {
        float: left;
        margin-left: 1rem;
    }
}

header nav ul li a {
    display: block;
}

@media (min-width: 576px) {
    header nav ul li a {
        display: block;
        padding: 0.425rem 0rem;
    }
}

@media (max-width: 768px) {
    ul {
        clear: both;
    }

    ul li {
        padding: 0.5em 0;
    }
}

.hide-nav {
    -webkit-transform: translateY(-120%) !important;
    transform: translateY(-120%) !important;
}

ul.menu_left {
    font-family: "Philosopher", sans-serif;
    font-weight: 500;
    display: block;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    z-index: 10;
}

@media (min-width: 768px) {
    ul.menu_left {
        display: block !important;
        float: right;
        max-height: none;
    }
}

ul.menu_left:before {
    content: "";
    display: table;
    clear: both;
}

ul.menu_left.collapse {
    max-height: 15em !important;
}

.nav_toggle {
    display: block;
    border-radius: 5px;
    background-color: transparent;
    float: right;
    height: 38px;
    width: 38px;
    cursor: pointer;
    padding: 8px 8px;
}

.nav_toggle.open span:first-child {
    transform: rotate(45deg) translate(4.4px, 4.4px);
}

.nav_toggle.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

.nav_toggle.open span:last-child {
    transform: rotate(-45deg) translate(4.4px, -4.4px);
}

@media (min-width: 768px) {
    .nav_toggle {
        display: none;
    }
}

.nav_toggle span {
    position: relative;
    display: block;
    height: 2px;
    width: 100%;
    margin-top: 4px;
    background-color: #000;
    transition: all 0.25s;
}

ul.menu_left.showMenu {
    max-height: 15em;
}
