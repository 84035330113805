@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.verso_final {
    align-items: center;
    background-color: #ded7c7;
    display: flex;
    flex-wrap: wrap;
    height: 2880px;
    justify-content: center;
    min-width: 1280px;
    overflow: hidden;
    padding: 35px 0px 0px 0px;
    position: relative;
    width: 100%;
}

.verso_final .home {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 34px 34px;
    justify-content: center;
    position: relative;
    width: 1197px;
}

.verso_final .frame {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 84px;
    position: relative;
}

.verso_final .group_home_frases {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    height: 332px;
    position: relative;
}

.verso_final .dos_dados_inova_o {
    color: #2c2c33;
    font-family: "Poppins", Helvetica;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 1.44px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
    width: fit-content;
}

.back {
    background: linear-gradient(45deg, #597991ce, #3e5151);
    /* Altere as cores conforme desejado */
    -webkit-background-clip: text;
    /* Isso permite que o gradiente seja aplicado ao texto */
    -webkit-text-fill-color: transparent;
    /* Isso torna o texto transparente para que o gradiente seja visível */
}

.verso_final .text_wrapper {
    color: #2c2c33;
    font-family: "Poppins", Helvetica;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    top: -50px;
    line-height: normal;
    position: relative;
    text-align: center;
    width: fit-content;
}

.verso_final .about {
    height: 84px;
    margin-right: -2px;
    position: relative;
    width: 599px;
    top: -75px;
}

.verso_final .ol_meu_nome_felipe {
    color: #2c2c33;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 300;
    height: 84px;
    left: 54px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 543px;
}

.verso_final .line {
    height: 2px;
    left: 0;
    position: absolute;
    top: 14px;
    width: 34px;
    border: none;
    background-color: #2c2c33;
}

.verso_final .buttons {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 26px;
    top: -25px;
    justify-content: center;
    position: relative;
}

.verso_final .btn_contact {
    align-items: center;
    background-color: #597991;
    border-radius: 10px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 13px 20px;
    position: relative;
    border: none;
    cursor: pointer;
}

.btn_contact:hover {
    background-color: #597991da;
    transform: translateY(-1px);
    box-shadow: 8px 8px 25px #00000040;
    transition: 600ms ease;
}

.verso_final .label {
    color: rgba(255, 255, 255, 1);
    font-family: "Poppins", Helvetica;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.41px;
    line-height: 22px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.verso_final .icon {
    flex: 0 0 auto;
    height: 22px;
    position: relative;
}

.verso_final .btn_portfolio {
    align-items: center;
    border-radius: 10px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: center;
    padding: 13px 20px;
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
}

/************/

/*Linha abaixo do menu*/
.div:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    transition: width 0s ease, background 0.35s ease;
}

/*Linha abaixo do menu*/
.div:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 0;
    background: #000;
    transition: width 0.35s ease;
}

/*Linha abaixo do menu*/
.div:hover:before {
    width: 100%;
    background: #2c2c33;
    transition: width 0.35s ease;
}

/*Linha abaixo do menu*/
.div:hover:after {
    width: 100%;
    background: transparent;
    transition: all 0s ease;
}

.btn_portfolio:hover {
    transform: scale(1.1);
    transition: transform 0.35s ease;
}

/************/

.verso_final .div {
    color: #2c2c33;
    font-family: "Poppins", Helvetica;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.41px;
    line-height: 22px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.verso_final .img {
    flex: 0 0 auto;
    position: relative;
}

.verso_final .social {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: center;
    position: relative;
}

button {
    cursor: pointer;
    border: none;
    border-color: none;
    background: none;
}

.linksocial {
    height: 45px;
    width: 35px;
}

.linksocial1 {
    height: 40px;
    width: 30px;
}

.linksocial_1 {
    height: 45px;
    width: 35px;
}

.linksocial:hover,
.linksocial1:hover,
.linksocial_1:hover {
    transform: scale(1.1);
}

.verso_final .text_wrapper_2 {
    color: #2c2c33;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
}

.verso_final .img_2 {
    height: 35px;
    position: relative;
    width: 30.5px;
}

.verso_final .instagram {
    height: 35px;
    position: relative;
    width: 30.25px;
}

.verso_final .img_home {
    height: 651px;
    position: relative;
    width: 565px;
}

.verso_final .overlap {
    height: 651px;
    left: 13px;
    position: relative;
    width: 539px;
}

.verso_final .rectangle {
    border: 0.5px solid;
    border-color: #000000cc;
    border-radius: 250px;
    height: 651px;
    left: 90px;
    position: absolute;
    top: 0;
    width: 360px;
}

.verso_final .lampada {
    height: 595px;
    left: 110px;
    object-fit: cover;
    position: absolute;
    top: 28px;
    width: 319px;
}

.verso_final .group {
    height: 45px;
    left: 340px;
    position: absolute;
    top: 532px;
    width: 181px;
}

.verso_final .overlap_group {
    background-color: #ffffff;
    border-radius: 50px;
    box-shadow: 10px 10px 25px #00000040;
    height: 45px;
    position: relative;
    width: 179px;
}

.verso_final .group_add {
    background-color: #d9d9d980;
    border-radius: 100px;
    height: 35px;
    left: 6px;
    position: absolute;
    top: 5px;
    width: 35px;
}

.verso_final .fluent_add_filled {
    height: 25px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 25px;
}

.verso_final .fluent_add_filled_2 {
    height: 25px;
    left: 5px;
    position: absolute;
    top: 5px;
    width: 25px;
}

.verso_final .text_wrapper_3 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 24px;
    left: 57px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
}

.verso_final .overlap_wrapper {
    height: 45px;
    left: 358px;
    position: absolute;
    top: 67px;
    width: 181px;
}

.verso_final .text_wrapper_4 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 24px;
    left: 62px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
}

.verso_final .overlap_group_wrapper {
    height: 45px;
    left: 0;
    position: absolute;
    top: 487px;
    width: 181px;
}

.verso_final .text_wrapper_5 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 24px;
    left: 49px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
}

/* Services*/
.verso_final .group_services {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 152px 152px;
    justify-content: center;
    position: relative;
    width: 100%;
    background-color: #e8e1d3;
    padding: 25px;
}

.verso_final .copy_services {
    height: 120px;
    position: relative;
    width: 534px;
}

.animateCopy {
    animation: animate-copy 1s ease-in-out;
}

@keyframes animate-copy {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.verso_final .as_melhores_solu_es {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 120px;
    left: 87px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
}

.verso_final .line_2 {
    height: 2px;
    position: absolute;
    top: 55px;
    width: 72px;
    border: none;
    background-color: #2c2c33;
}

.verso_final .services {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 25px;
    position: relative;
}

.animateService {
    animation: animate-service 1s ease-in-out;
}

@keyframes animate-service {
    from {
        opacity: 0;
        transform: translateX(100px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.verso_final .div_2 {
    height: 185px;
    position: relative;
    width: 491px;
    border-top: 2px solid;
    padding: 8px;
}

.div_2:hover {
    transform: translateY(-10px);
    box-shadow: 8px 8px 25px #00000040;
    transition: 600ms ease;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}


.verso_final .text_wrapper_6 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 60px;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 14px;
    width: 57px;
}

.verso_final .interfaces {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 36px;
    left: 102px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 130px;
    width: 347px;
}

.verso_final .desenvolvimento_de {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 121px;
    left: 102px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -20px;
    width: 348px;
}

.verso_final .text_wrapper_7 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 60px;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 14px;
    width: 68px;
}

.verso_final .p {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 38px;
    left: 102px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 130px;
    width: 367px;
}

.verso_final .desenvolvimento_de_2 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 127px;
    left: 102px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -20px;
    width: 348px;
}

.verso_final .text_wrapper_8 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 60px;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 14px;
    width: 68px;
}

.verso_final .interfaces_2 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 400;
    height: 36px;
    left: 102px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 130px;
    width: 346px;
}

.verso_final .analisededados {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 121px;
    left: 102px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: -20px;
    width: 383px;
}

/*Skills*/
.verso_final .social_proof_section {
    align-items: center;
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    gap: 0px 0px;
    justify-content: center;
    padding: 50px 0px;
    position: relative;
    width: 100%;
}

.verso_final .container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 80px 80px;
    justify-content: center;
    padding: 0px 32px;
    position: relative;
    width: 1000px;
}

.verso_final .logos {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 24px 24px;
    position: relative;
    width: 320px;
}

.verso_final .icon_instance_node {
    height: 56px !important;
    position: relative !important;
    width: 56.31px !important;
    box-shadow: 4px 10px 20px #00000024;
    border-radius: 10px;
    background-color: #ffffff;
}

.verso_final .icon_instance_node_2 {
    height: 56px !important;
    position: relative !important;
    width: 56px !important;
    box-shadow: 4px 10px 20px #00000024;
    border-radius: 10px;
    background-color: #ffffff;
}

.verso_final .group_pitch {
    align-items: center;
    display: flex;
    gap: 93px;
    justify-content: center;
    position: relative;
    width: 358px;
}

.verso_final .card_pitch {
    height: 303px;
    position: relative;
    width: 314px;
}

.verso_final .overlap_group_2 {
    height: 303px;
    position: relative;
    width: 308px;
}

.verso_final .rectangle_2 {
    border: 0.5px solid;
    border-color: #00000080;
    border-radius: 16px;
    height: 278px;
    left: 13px;
    position: absolute;
    top: 25px;
    width: 295px;
}

.verso_final .rectangle_3 {
    background-color: #ffffff;
    border: 0.5px solid;
    border-color: #000000bf;
    border-radius: 16px;
    height: 290px;
    left: 0;
    position: absolute;
    top: 0;
    width: 300px;
}

.verso_final .destaque_se_online {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 42px;
    left: 14px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 161px;
    width: 273px;
}

.verso_final .text_wrapper_9 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 42px;
    left: 18px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 218px;
    width: 265px;
}

.verso_final .text_wrapper_10 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 62px;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 84px;
    width: 268px;
}

.verso_final .rectangle_4 {
    background-color: #59799140;
    border-radius: 100px;
    height: 40px;
    left: 250px;
    position: absolute;
    top: 10px;
    width: 40px;
}

.verso_final .guidance_up_arrow {
    height: 32px;
    left: 250px;
    position: absolute;
    top: 12px;
    width: 40px;
}

.verso_final .contact {
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 20px 30px #00000026;
    display: flex;
    flex-wrap: wrap;
    gap: 50px 100px;
    height: 650px;
    justify-content: center;
    position: relative;
    width: 1024px;
}

.animateContact {
    animation: animate-contact 1s ease-in-out;
}

@keyframes animate-contact {
    from {
        opacity: 0;
        transform: translateY(50px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.verso_final .frame_2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 54px;
    padding: 8px;
    position: relative;
    width: 420px;
}

.verso_final .frame_3 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    margin-right: -8px;
    position: relative;
    width: 420px;
}

.verso_final .vamos_conversar {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    margin-top: -1px;
    width: fit-content;
}

.verso_final .text_wrapper_11 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    margin-right: -62px;
    width: 420px;
}

.verso_final .frame_4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.verso_final .text_wrapper_12 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.verso_final .social_2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 27px;
    justify-content: center;
    margin-right: -9px;
    position: relative;
}

.verso_final .frame_5 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    font-size: 16px;
}

.verso_final .frame_6 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    font-size: 16px;
}

.verso_final .text_wrapper_13 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.verso_final .rectangle_5 {
    background-color: #fafafa;
    height: 35px;
    position: relative;
    width: 380px;
    border-color: #2c2c330d;
    border-radius: 6px;
    font-size: 24px;
}

.verso_final .rectangle_6 {
    background-color: #fafafa;
    height: 100px;
    position: relative;
    width: 380px;
    border-color: #2c2c330d;
    border-radius: 6px;
    font-size: 24px;
}

.verso_final .div_wrapper {
    align-items: flex-start;
    background-color: #000000;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 13px 170px;
    position: relative;
    width: 385px;
    cursor: pointer;
    border-radius: 6px;
    border: none;
    outline: none;
    transition: 0.2s all;
}

.div_wrapper:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.verso_final .text_wrapper_14 {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
    align-items: center;
    justify-content: center;
}

.effect a {
    transition: all 0.2s linear 0.2s;
}

.effect a {
    text-decoration: none !important;
    width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 20px;
    font-size: 25px;
    overflow: hidden;
    position: relative;
    color: #212121;
    border: 0.5px solid #212121;
}

.effect a {
    transition: all 0.2s linear 0.2s;
}

.effect a {
    transition: all 0.2s linear 0s;
}

.effect a:hover {
    transition: all 0.2s linear 0s;
    border-color: rgba(33, 33, 33, 0);
}

.effect a:hover {
    transform: scale(1.1);
    text-shadow: 4px 0px 3px rgba(33, 33, 33, 0.3);
    transition: all 0.2s linear 0.2s;
}

@media only screen and (min-width: 1198px) and (max-width: 1280px) {

    .verso_final .home {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 34px 0px;
        justify-content: center;
        position: relative;
        width: 1197px;
    }

    .verso_final .overlap {
        height: 651px;
        left: -20px;
        position: relative;
        width: 500px;
    }

    .verso_final .services {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 25px;
        left: -80px;
        position: relative;
    }

}

@media only screen and (min-width: 993px) and (max-width: 1197px) {

    .verso_final {
        align-items: center;
        background-color: #ded7c7;
        display: flex;
        flex-wrap: wrap;
        height: 3600px;
        justify-content: center;
        min-width: 1024px;
        overflow: hidden;
        padding: 52px 0px 0px 0px;
        position: relative;
        width: 100%;
    }

    .verso_final .home {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 34px 34px;
        justify-content: center;
        position: relative;
        width: 1024px;
    }

    .verso_final .overlap {
        height: 651px;
        left: 15px;
        position: relative;
        width: 539px;
    }

    .verso_final .group_services {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 152px 152px;
        justify-content: center;
        position: relative;
        width: 90%;
    }

    .verso_final .services {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 25px;
        position: relative;
    }

}

@media only screen and (min-width: 769px) and (max-width: 992px) {

    .verso_final {
        align-items: center;
        background-color: #ded7c7;
        display: flex;
        flex-wrap: wrap;
        height: 4320px;
        justify-content: center;
        min-width: 768px;
        overflow: hidden;
        padding: 52px 0px 0px 0px;
        position: relative;
        width: 100%;
    }

    .verso_final .home {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 34px 34px;
        justify-content: center;
        position: relative;
        width: 768px;
    }

    .verso_final .overlap {
        height: 651px;
        left: 15px;
        position: relative;
        width: 539px;
    }

    .verso_final .group_services {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 152px 152px;
        justify-content: center;
        position: relative;
        width: 90%;
        padding: 50px 0px;
    }

    .verso_final .services {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 25px;
        position: relative;
    }

    .verso_final .contact {
        align-items: center;
        background-color: #ffffff;
        box-shadow: 0px 20px 30px #00000026;
        display: flex;
        flex-wrap: wrap;
        gap: 50px 100px;
        height: 1050px;
        justify-content: center;
        position: relative;
        width: 1024px;
    }

    .verso_final .frame_3 {
        gap: 0px;
    }

    .verso_final .frame_4 {
        gap: 0px;
        top: -25px;
    }

    .verso_final .social_2 {
        top: -25px;
    }

    .verso_final .frame_5 {
        top: -30px;
    }
}

@media only screen and (min-width: 500px) and (max-width: 768px) {

    .verso_final {
        align-items: center;
        background-color: #ded7c7;
        display: flex;
        flex-wrap: wrap;
        height: 4320px;
        justify-content: center;
        min-width: 550px;
        overflow: hidden;
        padding: 52px 0px 0px 0px;
        position: relative;
        width: 100%;
    }

    .verso_final .home {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 34px 34px;
        justify-content: center;
        position: relative;
        width: 499px;
    }

    .verso_final .dos_dados_inova_o {
        color: #2c2c33;
        font-family: "Poppins", Helvetica;
        font-size: 42px;
        font-weight: 700;
        letter-spacing: 1.44px;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        text-align: center;
        width: fit-content;
    }

    .verso_final .text_wrapper {
        color: #2c2c33;
        font-family: "Poppins", Helvetica;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        top: -50px;
        line-height: normal;
        position: relative;
        text-align: center;
        width: fit-content;
    }

    .verso_final .about {
        height: 84px;
        position: relative;
        width: 320px;
        top: -75px;
    }

    .verso_final .ol_meu_nome_felipe {
        color: #2c2c33;
        font-family: "Poppins", Helvetica;
        font-size: 14px;
        font-weight: 300;
        height: 84px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 0;
        width: 500px;
    }

    .verso_final .line {
        height: 2px;
        left: 0;
        position: absolute;
        top: 14px;
        width: 34px;
        border: none;
        background-color: #2c2c33;
    }

    .verso_final .buttons {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 26px;
        top: -60px;
        justify-content: center;
        position: relative;
    }

    .verso_final .social {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 20px;
        top: -60px;
        justify-content: center;
        position: relative;
    }

    .verso_final .overlap {
        height: 651px;
        left: 15px;
        position: relative;
        width: 539px;
    }

    .verso_final .contact {
        align-items: center;
        background-color: #ffffff;
        box-shadow: 0px 20px 30px #00000026;
        display: flex;
        flex-wrap: wrap;
        gap: 50px 100px;
        height: 1175px;
        justify-content: center;
        position: relative;
        width: 1024px;
    }

}

@media only screen and (min-width: 320px) and (max-width: 499px) {

    .verso_final {
        align-items: center;
        justify-content: center;
        display: flex;
        height: 3800px;
        min-width: 300px;
        padding: 80px 0px 0px 0px;
        width: 100%;
    }

    .verso_final .home {
        gap: 34px 34px;
        width: 300px;
    }

    .verso_final .group_home_frases {
        flex-direction: column;
        gap: 24px;
        height: 332px;
        position: relative;
    }

    .verso_final .dos_dados_inova_o {
        font-size: 22px;
    }

    .verso_final .text_wrapper {
        font-size: 14px;
    }

    .verso_final .about {
        height: 80px;
        position: relative;
        width: 250px;
    }

    .verso_final .ol_meu_nome_felipe {
        color: #2c2c33;
        font-family: "Poppins", Helvetica;
        font-size: 14px;
        font-weight: 300;
        height: 84px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: justify;
        top: 0;
        left: 40px;
        width: 250px;
    }

    .verso_final .line {
        height: 2px;
        left: 0;
        position: absolute;
        top: 14px;
        width: 25px;
        border: none;
        background-color: #2c2c33;
    }

    .verso_final .buttons {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        top: -80px;
        justify-content: center;
        position: relative;
    }

    .verso_final .btn_contact {
        gap: 8px;
        padding: 10px 14px;
    }

    .verso_final .label {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
    }

    .verso_final .icon {
        height: 18px;
    }

    .verso_final .btn_portfolio {
        gap: 8px;
        padding: 10px 14px;
    }

    .verso_final .social {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 20px;
        top: -100px;
        justify-content: center;
        position: relative;
    }

    .linksocial {
        height: 35px;
        width: 25px;
    }

    .linksocial1 {
        height: 35px;
        width: 25px;
    }

    .linksocial_1 {
        height: 38px;
        width: 28px;
    }

    .linksocial:hover,
    .linksocial_1:hover {
        transform: scale(1.1);
    }

    .verso_final .text_wrapper_2 {
        color: #2c2c33;
        font-family: "Poppins", Helvetica;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        position: relative;
        width: fit-content;
    }

    .verso_final .img_2 {
        height: 18px;
        position: relative;
        width: 18.5px;
    }

    .verso_final .instagram {
        height: 18px;
        position: relative;
        width: 18.25px;
    }

    .verso_final .img_home {
        height: 651px;
        position: relative;
        width: 310px;
    }

    .verso_final .overlap {
        top: -75px;
        height: 651px;
        left: 13px;
        position: relative;
        width: 310px;
    }

    .verso_final .rectangle {
        border: 0.5px solid;
        border-color: #000000cc;
        border-radius: 250px;
        height: 651px;
        left: -5px;
        position: absolute;
        top: 0;
        width: 280px;
    }

    .verso_final .lampada {
        height: 595px;
        left: 12px;
        object-fit: cover;
        position: absolute;
        top: 28px;
        border-radius: 250px;
        width: 250px;
    }

    .verso_final .group {
        height: 45px;
        left: 170px;
        position: absolute;
        top: 532px;
        width: 100px;
    }

    .verso_final .overlap_group {
        background-color: #ffffff;
        border-radius: 50px;
        box-shadow: 10px 10px 25px #00000040;
        height: 35px;
        position: relative;
        width: 125px;
    }

    .verso_final .group_add {
        background-color: #d9d9d980;
        border-radius: 100px;
        height: 25px;
        left: 6px;
        position: absolute;
        top: 5px;
        width: 25px;
    }

    .verso_final .fluent_add_filled {
        height: 18px;
        left: 0px;
        position: absolute;
        top: 0px;
        width: 18px;
    }

    .verso_final .fluent_add_filled_2 {
        height: 20px;
        left: 3px;
        position: absolute;
        top: 3px;
        width: 20px;
    }

    .verso_final .text_wrapper_3 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 24px;
        left: 38px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 10px;
    }

    .verso_final .overlap_wrapper {
        height: 45px;
        left: 160px;
        position: absolute;
        top: 67px;
        width: 181px;
    }

    .verso_final .text_wrapper_4 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 24px;
        left: 41px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 10px;
    }

    .verso_final .overlap_group_wrapper {
        height: 45px;
        left: 0;
        position: absolute;
        top: 487px;
        width: 181px;
    }

    .verso_final .text_wrapper_5 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 24px;
        left: 34px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 10px;
    }

    /* Services*/
    .verso_final .group_services {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 152px 152px;
        justify-content: center;
        position: relative;
        width: 100%;
    }

    .verso_final .copy_services {
        height: 120px;
        position: relative;
        width: 280px;
    }

    .verso_final .as_melhores_solu_es {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 20px;
        font-weight: 400;
        height: 80px;
        width: 220px;
        left: 50px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
    }

    .verso_final .line_2 {
        height: 2px;
        position: absolute;
        top: 25px;
        width: 35px;
        border: none;
        background-color: #2c2c33;
    }

    .verso_final .services {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 25px;
        top: -100px;
        position: relative;
    }

    .verso_final .div_2 {
        height: 175px;
        position: relative;
        width: 280px;
        border-top: 2px solid;
        padding: 8px;
    }

    .div_2:hover {
        transform: translateY(-10px);
        box-shadow: 8px 8px 25px #00000040;
        transition: 600ms ease;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }


    .verso_final .text_wrapper_6 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 24px;
        font-weight: 400;
        height: 60px;
        left: 10px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 14px;
        width: 30px;
    }

    .verso_final .interfaces {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 36px;
        left: 60px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 100px;
        width: 250px;
    }

    .verso_final .desenvolvimento_de {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 24px;
        font-weight: 400;
        height: 121px;
        left: 60px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: -7px;
        width: 270px;
    }

    .verso_final .text_wrapper_7 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 24px;
        font-weight: 400;
        height: 60px;
        left: 10px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 14px;
        width: 30px;
    }

    .verso_final .p {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 38px;
        left: 60px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 100px;
        width: 210px;
    }

    .verso_final .desenvolvimento_de_2 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 24px;
        font-weight: 400;
        height: 127px;
        left: 60px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: -7px;
        width: 270px;
    }

    .verso_final .text_wrapper_8 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 24px;
        font-weight: 400;
        height: 60px;
        left: 10px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 14px;
        width: 30px;
    }

    .verso_final .interfaces_2 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 12px;
        font-weight: 400;
        height: 36px;
        left: 40px;
        letter-spacing: -0.48px;
        line-height: normal;
        position: absolute;
        top: 100px;
        width: 250px;
    }

    .verso_final .analisededados {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 24px;
        font-weight: 400;
        height: 70px;
        left: 60px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: -7px;
        width: 260px;
    }

    /*Skills*/
    .verso_final .social_proof_section {
        align-items: center;
        background-color: #ffffff;
        display: flex;
        flex-wrap: wrap;
        gap: 0px 0px;
        justify-content: center;
        padding: 50px 0px;
        position: relative;
        top: -50px;
        width: 100%;
    }

    .verso_final .container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        gap: 80px 80px;
        justify-content: center;
        padding: 0px 32px;
        position: relative;
        width: 1000px;
    }

    .verso_final .logos {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        gap: 24px 24px;
        position: relative;
        width: 310px;
    }

    .verso_final .icon_instance_node {
        height: 56px !important;
        position: relative !important;
        width: 56.31px !important;
        box-shadow: 4px 10px 20px #00000024;
        border-radius: 10px;
        background-color: #ffffff;
    }

    .verso_final .icon_instance_node_2 {
        height: 56px !important;
        position: relative !important;
        width: 56px !important;
        box-shadow: 4px 10px 20px #00000024;
        border-radius: 10px;
        background-color: #ffffff;
    }

    .verso_final .group_pitch {
        align-items: center;
        display: flex;
        gap: 93px;
        justify-content: center;
        position: relative;
        width: 310px;
    }

    .verso_final .card_pitch {
        height: 303px;
        position: relative;
        width: 300px;
    }

    .verso_final .overlap_group_2 {
        height: 303px;
        position: relative;
        width: 300px;
    }

    .verso_final .rectangle_2 {
        border: 0.5px solid;
        border-color: #00000080;
        border-radius: 16px;
        height: 278px;
        left: 13px;
        position: absolute;
        top: 25px;
        width: 280px;
    }

    .verso_final .rectangle_3 {
        background-color: #ffffff;
        border: 0.5px solid;
        border-color: #000000bf;
        border-radius: 16px;
        height: 290px;
        left: 0;
        position: absolute;
        top: 0;
        width: 280px;
    }

    .verso_final .destaque_se_online {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 14px;
        font-weight: 400;
        height: 42px;
        left: 12px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 161px;
        width: 265px;
    }

    .verso_final .text_wrapper_9 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 14px;
        font-weight: 400;
        height: 42px;
        left: 14px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 218px;
        width: 265px;
    }

    .verso_final .text_wrapper_10 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 14px;
        font-weight: 400;
        height: 62px;
        left: 12px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 84px;
        width: 268px;
    }

    .verso_final .rectangle_4 {
        background-color: #59799140;
        border-radius: 100px;
        height: 40px;
        left: 230px;
        position: absolute;
        top: 10px;
        width: 40px;
    }

    .verso_final .guidance_up_arrow {
        height: 30px;
        left: 230px;
        position: absolute;
        top: 15px;
        width: 38px;
    }

    .verso_final .contact {
        align-items: center;
        background-color: #ffffff;
        box-shadow: 0px 20px 30px #00000026;
        display: flex;
        flex-wrap: wrap;
        gap: 50px 80px;
        height: 920px;
        justify-content: center;
        position: relative;
        width: 300px;
        top: -25px;
    }

    .verso_final .frame_2 {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 8px;
        position: relative;
        width: 280px;
    }

    .verso_final .frame_3 {
        align-items: flex-start;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 8px;
        margin-right: -8px;
        position: relative;
        width: 280px;
    }

    .verso_final .vamos_conversar {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        position: relative;
        margin-top: -1px;
        width: fit-content;
    }

    .verso_final .text_wrapper_11 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        position: relative;
        margin-right: -62px;
        width: 280px;
    }

    .verso_final .frame_4 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 8px;
        position: relative;
    }

    .verso_final .text_wrapper_12 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        width: fit-content;
    }

    .verso_final .social_2 {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        margin-right: 0px;
        position: relative;
    }

    .verso_final .frame_5 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 12px;
        top: -30px;
        position: relative;
    }

    .verso_final .frame_6 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 8px;
        position: relative;
    }

    .verso_final .text_wrapper_13 {
        color: #000000;
        font-family: "Poppins", Helvetica;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        width: fit-content;
    }

    .verso_final .rectangle_5 {
        background-color: #f7f7f7;
        height: 35px;
        position: relative;
        width: 280px;
        border-color: #2c2c330d;
        border-radius: 6px;
        font-size: 24px;
    }

    .verso_final .rectangle_6 {
        background-color: #f7f7f7;
        height: 100px;
        position: relative;
        width: 280px;
        border-color: #2c2c330d;
        border-radius: 6px;
    }

    .verso_final .div_wrapper {
        align-items: flex-start;
        background-color: #000000;
        display: flex;
        flex: 0 0 auto;
        gap: 15px;
        top: 10px;
        padding: 13px 100px;
        position: relative;
        width: 280px;
        cursor: pointer;
        border-radius: 6px;
        border: none;
        outline: none;
        transition: 0.2s all;
    }

    .div_wrapper:active {
        transform: scale(0.98);
        box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    }

    .verso_final .text_wrapper_14 {
        color: #ffffff;
        font-family: "Poppins", Helvetica;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        width: fit-content;
        align-items: center;
        justify-content: center;
    }

    .effect a {
        transition: all 0.2s linear 0.2s;
    }

    .effect a {
        text-decoration: none !important;
        width: 35px;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 20px;
        font-size: 25px;
        overflow: hidden;
        position: relative;
        color: #212121;
        border: 0.5px solid #212121;
    }

    .effect a {
        transition: all 0.2s linear 0.2s;
    }

    .effect a {
        transition: all 0.2s linear 0s;
    }

    .effect a:hover {
        transition: all 0.2s linear 0s;
        border-color: rgba(33, 33, 33, 0);
    }

    .effect a:hover {
        transform: scale(1.1);
        text-shadow: 4px 0px 3px rgba(33, 33, 33, 0.3);
        transition: all 0.2s linear 0.2s;
    }


    .desenvolvidoby {
        font-size: 10px;
    }
}